@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* Global App */

:root{
  --primary-color:#0555DA;
  --secondary-color:#F8D448;
  --background-color:#F9FBFF;
  --dark-color:#212529;
  --blue-subtle:#F3F6FD;
  --grey-color:#EFF0F6;
  --green-color:#008A7C;
}

img{
  object-fit: cover;
}

.main {
  font-family: 'Outfit', sans-serif;
  font-weight:400;
  width: 100% !important;
  overflow-x:hidden;
  background-color:var(--background-color);
}

a{
  text-decoration:none !important;
}

input,textarea,select{
  outline:none;
  border:none;
}

.bg-blue{
  background-color:var(--primary-color);
}

.bg-orange{
  background-color:var(--secondary-color);
}

.bg-blue-subtle{
  background-color:var(--blue-subtle);
}

.bg-grey{
  background-color:var(--grey-color);
}

.bg-green{
  background-color:var(--green-color);
}

.text-green{
  color:var(--green-color);
}


.text-blue{
  color:var(--primary-color);
}

.text-orange{
  color:var(--secondary-color);
}

.button-orange{
  background-color:var(--secondary-color);
  border: 1px solid var(--secondary-color);
  color:var(--dark-color);
}

.button-orange:hover{
  background-color:white;
}

.button-blue{
  background-color:var(--primary-color);
  border: 1px solid var(--primary-color);
  color:white;
}

.button-blue:hover{
  color:var(--primary-color);
  background-color:white;
}

.border-blue-start-3{
  border-left:3px solid var(--primary-color);
}

.border-blue{
  border:1px solid var(--primary-color);
}


/* Header component */

.header-btn-menu:hover,.close-left-menu:hover{
  opacity: 0.8;
}

.header{
  position:absolute;
  width:100%;
  left:0;
  top:0;
  z-index:1;
}

.menu-modal nav a{
  text-decoration:none;
}

.event-icon:hover{
  background-color: var(--primary-color);
  color:white;
}

.modal-footer select, .modal-footer a{
  width:80%;
  height:1.5cm;
  display:flex;
  align-items:center;
  justify-content:center;
  align-self:center;
  justify-self:center;
  margin-bottom:10px;
  box-shadow:none !important;
  border-radius:0 !important;
  border-radius:3px !important;
}

.header-nav-list{
  list-style-type:none;
  display:flex;
  width: 100%;
  justify-content:space-around;
  align-items:center;
  align-self:center;
  padding:0;
  margin:0;
}

.header-nav-list li a {
  color: var(--dark-color) !important; /* Couleur par défaut des liens */
}

.header-nav-list li a:hover{
  color:var(--primary-color) !important;
}

.header-nav-list li a.active{
  color:var(--primary-color)  !important;
}

.left-menu-nav a{
  color:black;
}

.left-menu-nav a:hover{
  color:var(--primary-color) ;
}


.step{
  width:50px;
  height:50px;
  background-color:var(--blue-subtle);
  display: flex;
  justify-content:center;
  align-items:center;
  border-radius:50%;
  color:var(--primary-color);
  font-weight:500;
}

/* end of Header */


.wave{
  position: absolute;
  bottom:0;
  left:0;
  width:100%;
  height:100px;
  background:url(./assets/home/wave.svg);
}

.wave1{
  animation: waveanimate 30s linear infinite;
  z-index:1000;
  opacity: 1;
  animation-delay:0s;
  bottom:0;
}

.wave2{
  animation: waveanimate2 15s linear infinite;
  z-index:999;
  opacity: 0.5;
  animation-delay:-5s;
  bottom:10px;
}

.wave3{
  animation: waveanimate2 30s linear infinite;
  z-index:998;
  opacity: 0.7;
  animation-delay:-2s;
  bottom:15px;
}

.wave4{
  animation: waveanimate2 5s linear infinite;
  z-index:997;
  opacity: 0.7;
  animation-delay:-5s;
  bottom:20px;
}

@keyframes waveanimate {
  0% {
    background-position-x:0;
  }
  100% {
    background-position-x:1000px;
  }
}

@keyframes waveanimate2 {
  0% {
    background-position-x:0;
  }
  100% {
    background-position-x:-1000px;
  }
}

/* end of background */

/* Service component */
.moveUpDown{
  position: relative;
  animation: moveUpDown 6s infinite linear;
}

@keyframes moveUpDown {
  0%{top:0;}
  25%{top:30px;}
  50%{top:0px;}
  75%{top:-30px;}
  100%{top:0px}
}

/* end of Service */


/* Pricing component */

.pricing-component .pricing-item{
  border: 1px solid #ececec;
}


.pricing-component:nth-child(2) .pricing-item{
  border: 2px solid var(--primary-color);
}

.pricing-component button{
  border: 1px solid #ececec;
}

.pricing-component:nth-child(2) button{
  background-color:var(--primary-color);
  color:white;
}

.pricing-component button:hover{
  color:var(--primary-color);
  background-color:white;
  border: 1px solid var(--primary-color);
  
}

/* End of pricing*/


/* partenaires component */

.marquee-container {
  overflow: hidden;
  white-space: nowrap;
}

.marquee-content {
  display: inline-block;
  animation: marqueeAnimation 30s linear infinite;  /* Ajustez la vitesse selon vos besoins */
}

/* .marquee-container:hover .marquee-content {
  animation-play-state:paused;
} */

@keyframes marqueeAnimation {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

/* End of partenaires */


/* Solutions view */

@keyframes typing {
  from { width: 0; }
}

.typed-text {
  display: inline-block;
  overflow: hidden; 
  border-right: .15em solid var(--secondary-color); 
  margin: 0 auto; 
  animation: typing 5s steps(40, end), blink-caret .75s step-end infinite;
}

@keyframes blink-caret {
  from, to { border-color: transparent; }
  50% { border-color: var(--secondary-color) }
}

/* End of solutions */


.word-container {
  position: relative;
  height:80px;
  overflow: hidden;
}


.word-animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: fall 2s ease-in-out infinite;
}

@keyframes fall {
  0% {
    transform: translateY(-100%);
  }
  25% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}


.rs-picker-toggle {
  border:none !important;
  box-shadow:none !important;
  z-index: 1 !important;
  padding:0 !important;
}

.rs-stack-item svg{
  display:none;
}

.rs-steps-item-icon-process{
  background-color:var(--primary-color) !important;
  border-radius:50%;
  border:1px solid var(--primary-color);
}

.rs-steps-item-icon-wrapper{
  border:none !important;
  background-color:transparent !important;
}

.rs-steps-item-icon-wait{
  border:1px solid var(--primary-color);
  border-radius:50%;
  color: var(--primary-color);
}

.rs-steps-item-icon-finish{
  border:1px solid var(--primary-color);
  color: var(--primary-color);
  border-radius:50%;
}

.rs-steps-item-title:after{
  border-color:var(--primary-color) !important;
}

.rs-slider-handle:before{
  border-color:var(--primary-color) !important;
}

.rs-slider-progress-bar{
  background-color:var(--primary-color) !important;
}